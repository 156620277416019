
:root {
  --fiding-black : #00000025;
  --theme : #28a745;
  --theme-rgb: rgb(40,167,69);
}
[lang="ar"] input[type="checkbox"]::after {
  right: 10%;
}
[lang="ar"] input:checked[type="checkbox"]::after {
    right: 50%;
}

a {
  color: unset;
  text-decoration: none !important;
}

body{
  display: flex;
  flex-direction: column;
  *{
    /* font-size: 1rem; */
    line-height: 1.5;
    background-color: transparent;
    color: inherit;
  }
}
.App{
  height: 100%;
  transition: background-color 0.3s ease;
  
}

/* checkbox styling */

.check-box {
	transform: scale(2);
}

input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 50px;
    height: 26px;
    background: #ccc;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.4s;
    min-width: 50px
}

input:checked[type="checkbox"] {
  background: var(--theme);
}

input:checked[type="checkbox"].dark-check-box {
    background: var(--dark);
}

input[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 20px;
    height: 20px;
    top: 3px;
    left: 10%;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transform: scale(1.1);
    transition: 0.4s;
}

input:checked[type="checkbox"]::after {
    left: 50%;
}

/* end checkbox styling */

#Content{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.bg-color-white{
  background-color: var(--white) !important;
}
.bg-color-black{
  background-color: var(--black);
}
.text-white{
  color: var(--white) !important;
}

.bg-color-theme{
  background-color: var(--theme) !important;
}

.bg-color-grey{
  background-color: var(--grey) !important;
}
.bg-color-dark-white{
  background-color: var(--dark-white) !important;
}
.bg-color-very-light-theme{
  background-color: var(--very-light-theme) !important;
}

.mh-500{
  max-height: 500px !important;
}
.mw-500{
  max-width: 500px !important;
}

.mw-700{
  max-width: 700px !important;
}

.mw-900{
  max-width: 900px !important;
}

.success label{
  color: var(--theme) !important;
}
.success input{
  border-color: var(--theme);
}
.error label{
  color:red !important;
}

.error input{
  border-color: red !important;
}

.image-in-circle{
  position: relative;
  display: block;
  border-radius: 50%;
  aspect-ratio: 1/1;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
}
.fs-larger{
  font-size: larger;
}

.comment {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

dialog {
  border: 1px solid ;
  border-radius: 5px;
}
dialog:-internal-dialog-in-top-layer::backdrop {
    background: rgba(0, 0, 0, 0.7);
}

.main-image{
  position: relative;
  margin-bottom: 8px;
  border-radius: inherit;
  width: 100%;
  aspect-ratio: 1/1;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain !important;
}

.square {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
}

.square img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  min-width: 100%;
  min-height: 100%;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  height: fit-content;
  white-space: nowrap;
}

.hide-search .dropdown-content .search{
  display: none !important;
}
.center-all *{
  text-align: center;
}
.parameters {
  position: absolute;
  overflow: hidden;
}

.parameters div:hover,
.parameters div:focus {
  background-color: var(--theme);
  color: var(--white);
  cursor: pointer;
}
.z-index-1{
  z-index: 1;
}
.enlage-hover:hover *,
.enlage-hover:focus *{
  font-size: 1.2rem !important;
  text-align: left !important;
}
.enlage-hover-lg:hover *,
.enlage-hover-lg:focus *{
  font-size: 1.5rem !important;
}
.z-index-0{
  z-index: 0;
}

.outline-0{
  outline: 0;
}

/*  RMSC */
.dropdown-content{
  padding: 0 !important;
  margin-top: 3px;
  & .options{
    padding: 0 !important;
  }
  & .item-renderer{
    display: flex !important;
    align-items: center !important;
    & span{
      margin-left: 5px;
      margin-right: 5px;
    }
   
  }
} 
.multi-select{
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(var(--theme), .25)
}
.rmsc {
  --rmsc-main: var(--bs-border-color) !important;
  *:not([type="checkbox"]){
    color: inherit !important;
    background-color: inherit !important;
  }
  *{
    transition: all 0s !important;
  }
}
.rmsc .dropdown-container{
  background-color: var(--white) !important;
  color: inherit !important;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  display: block !important;
}
.dropdown-heading{
  border-radius: inherit;
}

.dropdown-container .search{
  display: flex !important;
}
.dropdown-container .search-clear-button{
  position: relative !important;
  margin: auto 2px !important;
  padding: 0 !important;
}
.rmsc .search input{
  padding: 8px !important;
}

textarea, input{
  background-color: var(--white);
}
.text-000{
  color: #dbdbdb !important;
}

input[type=range] {
  -webkit-appearance: none; /* Remove the default appearance */
  height: 10px; /* Set the height of the track */
  background: var(--theme); /* Set the background color of the track to green */
  border-radius: 5px; /* Set border radius */
  outline: none; /* Remove the default focus styles */
  border: none !important;

}
/* Styles for the thumb */
input[type=range]::-webkit-slider-thumb {
  border: 1px var(--white) solid;
  -webkit-appearance: none; /* Remove the default appearance */
  width: 20px; /* Set the width */
  height: 20px; /* Set the height */
  background: var(--theme); /* Set the background color of the thumb to green */
  border-radius: 50%; /* Create a round thumb */
  cursor: pointer; /* Add a cursor on hover */
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5); /* Add a shadow */
}

.search-area{
  border: none;
  outline: none;
  resize: none;
  white-space: nowrap;
  overflow: hidden;
}

/* Hide Header */
#Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: transform 0.3s ease;
}

.header-hidden {
  transform: translateY(-100%);
}

#main{
    padding-bottom: 80px;
    flex-grow: 1;
    margin-top: 53px;
}
@media(max-width:700px) {
  #main{
    margin-top:103px;
  }
}

.suspended-component {
  opacity: 0.5; /* Set the initial opacity */
  transition: opacity 0.3s ease; /* Add a transition effect for smoothness */
}

.google-ads{
  background-color: var(--theme) !important;
}

dialog {
  animation: fade-in-slide-up 300ms forwards;
}

@keyframes fade-in-slide-up{
  0%{
    transform: translateY(100%);
    opacity: 0;
  }
  100%{
    transform: translateY(0px);
    opacity: 1;

  }
}